.main-menu {

  .dropdown.menu {
    li {
      a {
        background-color: $primary-color;
        border-bottom: 2px solid transparent;
        padding: 1.5rem 2.3rem;
        text-transform: uppercase;
        color: $white;


        &.active,
        &:hover {
          border-bottom: 2px solid $white;
        }
      }

      &:first-child {
        a {
        }
      }

      &.is-active {
        > a {
          border-bottom: 2px solid $white;
        }
      }
    }
  }

  .is-dropdown-submenu.menu {
    li {
      a {
        text-align: left;
        text-transform: none;

        &.active,
        &:hover {
          border-bottom: 2px solid transparent;
          background-color: darken($primary-color, 10%);
        }
      }
    }
  }
}

// Off-Canvas

.off-canvas-btn {
  padding: 1rem;
  margin-top: -30px;
  text-align: right;

  .menu-icon {
    width: 30px;

    &::after {
      height: 3px;
    }
  }
}


.off-canvas {
  padding-top: 3.5rem;

  li {
    display: block;
    width: 100%;

    a {
      border-right: 0 none;
      border-bottom: 0 none;
      padding: 1rem 0.8rem;
      text-transform: uppercase;
      color: $black;


      &.active,
      &:hover {
        background-color: transparent;
        border-bottom: 0 none;
      }
    }

    .menu {
      padding-left: 1rem;
    }
  }
}