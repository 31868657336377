/** Wrapper Styles **/

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  font-weight: 700;
}

h5, h6 {
  font-weight: 400;
}

a {
  transition: all 200ms linear;
}

div,
img {
  outline: none !important;
}

.wrapper {
  overflow: hidden;
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
  float: left;
}

.row {
  clear: both;
}

.section {
  padding: 50px 0;
}

.gray-background,
.gray {
  background-color: $light-gray;

  h1 {
    color: $dark-gray;

    &:after {
      display: none;
    }
  }
}

.clear {
  clear: both;
}

.bold {
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}

.big {
  text-transform: uppercase;
}

.red {
  color: $primary-color;
}

.blue-background {
  background-color: #f1f1f1;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}