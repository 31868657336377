.header {
  padding-top: 2rem;

  .grid-container {
    position: relative;
  }

  .logo {
    position: absolute;
    top: -30px;
    left: 0.9375rem;
    z-index: 2;
    padding: 1rem;
    background: $white;
    //box-shadow: $box-shadow;
  }
}