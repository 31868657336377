
@include breakpoint(medium down) {

  .header .logo {
    width: 27%;
  }
}


@include breakpoint(495px down) {
  .special p span {
    font-size: rem-calc(50);
  }
}

@include breakpoint(small down) {

  .header .logo {
    width: 30%;
  }

  .special p {
    font-size: rem-calc(25);
  }

  .special p span {
    font-size: rem-calc(35);
  }

  .icon-nav,
  .footer,
  .teaser {
    .cell {
      margin-bottom: 1rem;
    }
  }

  .icon-nav img {
    width: 50%;
  }

  .submit-btn {
    margin-top: 1.5rem;
    float: none !important;

    input {
      width: 100%;
    }
  }

  .footer {
    text-align: center;

    .menu {
      float: none;
      margin-top: 1.5rem;
    }
  }
}
