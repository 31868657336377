blockquote {
  position: relative;
  display: inline-block;
  background: #eee;
  padding: 40px 30px 30px 60px;
  margin-top: 2rem;

  cite {
    padding-top: 1rem;
  }

  &::after,
  &::before {
    display: block;
    position: absolute;
    top: -40px;
    left: 10px;
    content: "\"";
    font-size: 120px;
    color: $primary-color
  }

  &::after {
    top: auto;
    left: auto;
    right: 10px;
    bottom: -100px;
  }
}

ul.list {
  margin-left: 1rem;

  li {
    position: relative;
    list-style: none;
    padding-left: 30px;

    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 10px;
      background-color: $primary-color;
      position: absolute;
      top: 8px;
      left: -15px;
    }
  }
}

h3.title {
  border-top: 2px solid $primary-color;
  border-bottom: 2px solid $primary-color;
  padding: 2rem 0;
  margin-bottom: 2rem;
}

// Teaser Box

.teaser {
  position: relative;

  a {
    position: relative;
    display: block;
  }

  .teaser-image {
    img {
      opacity: 0.7;
    }
  }

  a:hover {
    .teaser-image {
      img {
        opacity: 1;
      }
    }
  }

  .teaser-text {
    background: rgba(#284b47, 0.9);
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    padding: 0.75rem 0;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.special {
  background-color: #284b47;
  color: $white;

  .cell {
    border-top: 2px solid $white;
    border-bottom: 2px solid $white;
    padding: 25px 0;
  }

  p {
    font-size: rem-calc(41);
    margin: 0;
    text-transform: uppercase;

    span {
      font-size: rem-calc(100);
      font-weight: 700;
      line-height: normal;
      display: block;
    }
  }
}

.link-text {
  display: block;
  font-size: rem-calc(20);
  font-weight: 700;
  color: $black;
}

.news {
  h5 {
    background-color: $primary-color;
    color: $white;
    padding: 3rem 1rem 1rem;
    margin-bottom: 2rem;
  }

  p {
    padding-left: 1rem;
    font-weight: 700;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $black;
  }

  small {
    font-weight: 400;
  }

  a {
    color: $black;

    &:hover {
      color: $primary-color;
    }
  }
}