.footer {
  border-top: 5px solid #e1e1e1;
  border-bottom: 50px solid $primary-color;
  margin-top: 50px;

  .footer-menu {
    margin-bottom: 14px;
  }

  .menu {
    display: inline-block;
    float: right;
    border-top: 2px solid $black;

    a {
      color: $black;
      &:hover {
        color: $primary-color;
      }
    }

    &.align-right.vertical li {
      display: inline-block;
    }
  }
}

body.kontakt {
  .footer {
    margin-top: 0;
  }
}